import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';

const SubscriptionCommerceFaqPage = () => (
  <Layout>
    <Seo title="Subscription Commerce FAQ" />

    <Header graphic={undefined}>Subscription Commerce FAQ</Header>

    <section className="bg-white text-sp-dark">
      <Container className="my-24 ">
        <div className="prose prose-lg max-w-none prose-headings:font-normal prose-h2:text-4xl prose-h3:text-3xl">
          <h2>What Is E-Commerce Subscription Commerce?</h2>
          <p>
            E-commerce subscription commerce refers to the business model where customers commit to purchasing products or
            services from a brand on a recurring basis. This model is ideal for direct-to-consumer (DTC) brands as it helps in
            revenue prediction, better inventory management, and builds a more reliable customer base.
          </p>

          <h2>Why Should DTC Brands Consider Subscription Commerce?</h2>
          <ul>
            <li>
              <span>
                <strong className="font-bold">Predictable Revenue:</strong> Knowing how much you'll earn each month allows for
                better planning.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Customer Retention:</strong> Subscriptions foster long-term relationships.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Reduced Costs:</strong> It's cheaper to maintain a customer than acquire a new one.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Optimized Inventory:</strong> Recurring orders make inventory management easier.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Increased Brand Engagement:</strong> The recurring nature of subscriptions keeps
                your brand top-of-mind for consumers, increasing the chances of additional interactions and sales.
              </span>
            </li>
          </ul>

          <h2>What Are the Types of E-Commerce Subscriptions?</h2>
          <ul>
            <li>
              <span>
                <strong className="font-bold">Replenishment:</strong> Automatically reorders and sends products to the consumer at
                a regular interval. Great for consumable products like nutritional supplements, skincare, water filters.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Curation:</strong> Sends a curated box of assorted products to subscribers at
                regular intervals. Think Birchbox or snack boxes. Wine clubs are also a popular example.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Access Subscriptions:</strong> Consumers pay a recurring fee to get access to
                exclusive services or lower prices. Amazon Prime is an example.
              </span>
            </li>
          </ul>

          <h2>What Software Do I Need to Manage Subscriptions?</h2>
          <p>
            Managing subscriptions efficiently requires specialized software. Your SaaS should be capable of handling recurring
            billing, customer management, and inventory tracking. Look for platforms that are scalable, offer API support, and
            integrate easily with your existing tech stack.
          </p>

          <h2>What Are the Risks?</h2>
          <ul>
            <li>
              <span>
                <strong className="font-bold">Churn:</strong> Customers might cancel if they don't find value in your offering.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Complexity:</strong> More administrative work in tracking subscriptions, renewals,
                and cancellations.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Dependency:</strong> Heavy reliance on recurring revenue can be risky if a large
                number of subscribers churn at once.
              </span>
            </li>
          </ul>

          <h2>How Do I Handle Customer Retention?</h2>
          <ul>
            <li>
              <span>
                <strong className="font-bold">Regular Updates:</strong> Keep your product or service fresh and engaging.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Quality Customer Service:</strong> Fast and effective support can keep customers
                satisfied.
              </span>
            </li>
            <li>
              <span>
                <strong className="font-bold">Incentives for Loyalty:</strong> Discounts or bonuses for long-term subscribers can
                go a long way.
              </span>
            </li>
          </ul>

          <h2>What Is Churn?</h2>
          <p>
            Churn refers to the rate at which customers leave a subscription service, discontinue using a product, or stop
            engaging with a brand during a specific timeframe. It's a critical metric for any subscription-based business and
            provides insights into customer retention.
          </p>

          <h2>How Do I Calculate Customer Churn Rate?</h2>
          <p>
            <strong className="font-bold">Customer Churn Rate =</strong> (Number of Customers Churned / Number of Customers at
            Start of Period) × 100
          </p>

          <h2>Why Is Calculating Churn Important?</h2>
          <p>
            Understanding your churn rate helps you gauge the health of your customer base and the effectiveness of your retention
            strategies. A high churn rate could indicate dissatisfaction, while a low rate suggests strong customer loyalty.
          </p>

          <h2>How Can I Calculate Customer Lifetime Value (CLV) in Subscription Commerce?</h2>
          <p>
            <strong className="font-bold">CLV =</strong> (Average Order Value x Purchase Frequency) / Churn Rate
          </p>
          <p>
            Understanding CLV will help you gauge the value a customer brings over the entire subscription period, helping you in
            customer segmentation and targeted marketing.
          </p>

          <h2>How Do Regulations Like GDPR Affect Subscription Commerce?</h2>
          <p>
            GDPR and similar regulations require you to obtain explicit consent from customers before storing or processing their
            data. Make sure your subscription management software complies with these regulations to avoid hefty fines.
          </p>
        </div>
      </Container>
    </section>

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default SubscriptionCommerceFaqPage;
